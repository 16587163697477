<template>


  <div class="home-parent d-flex">

    <div class="home-left">
      <img src="../assets/logo.jpg" height="80" alt="logo">
      <div class="left-margin">
        <h1 class="home-title" tabindex="0">Iniciar una reclamación de deuda</h1>
        <p class="home-text mt-5">

          Si eres acreedor comercial de la administración pública
          tienes derecho al cobro de intereses de demora y a una indemnización en concepto de costes de cobro.
          Calcula con nosotros los intereses de demora generados y tramita la reclamación.
        </p>

        <div v-if="claimStarted" class="d-flex">
          <button  @click="()=>$router.push('claim')"
                   class="vf-btn vf-btn-lg vf-btn-full mr-3">Continuar
          </button>

          <button  @click="resetClaim"
                   class="vf-btn vf-btn-primary vf-btn-lg vf-btn-full">Nueva
          </button>

        </div>

        <div v-else>
          <button  @click="()=>$router.push('claim')"
                   class="vf-btn vf-btn-primary vf-btn-lg vf-btn-full">Iniciar
          </button>
        </div>

      </div>


    </div>

    <div class="home-right pl-5">
      <img src="../assets/paperwork.jpg" height="480" alt="logo">
    </div>



  </div>


</template>

<script>



import {mapGetters} from "vuex";
import {RESET_CLAIM} from "@/store/actions.type";

export default {
  name: "home",
  components: {
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters(['claimStarted'])
  },
  methods: {
    async resetClaim() {
      await this.$store.dispatch(RESET_CLAIM)
      await this.$router.push('claim')
    }
  },
  async mounted() {
  }



};
</script>

<style scoped>

.left-margin {
  padding-left: 30px;
}

.home-text {
  font-family: "reclamatec", serif;
  font-size: 1.125rem;
  color: #757575;
  line-height: 1.625rem;
  font-weight: 400;
  max-width: 500px;
}

.home-parent {
  background: #F7F7F7;
  height: 800px;
}

.home-left {

}

.home-title {
  width: 400px;
  font-family: "reclamatec", serif;
  font-size: 3.2rem;
  color: #333333;
  line-height: 2.625rem;
  font-weight: 500;
}

.home-logo {
  height: 300px;
  background-repeat: no-repeat;
  min-height: 32rem;
  background-size: auto 32rem;
  background-position: 49.6vw 0;
  background-image: url("../assets/logo.jpg");

}

.home-header {
  height: 100%;
  background-repeat: no-repeat;
  min-height: 32rem;
  background-size: auto 32rem;
  background-position: 49.6vw 0;
  background-image: url("../assets/paperwork.jpg");
}

</style>
